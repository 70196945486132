<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div class="my-swipe">
      <van-swipe :autoplay="5000" indicator-color="white">
        <van-swipe-item v-if="list.coverImg1">
          <img class="xq_img" :src="list.coverImg1" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg2">
          <img class="xq_img" :src="list.coverImg2" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg3">
          <img class="xq_img" :src="list.coverImg3" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg4">
          <img class="xq_img" :src="list.coverImg4" />
        </van-swipe-item>
      </van-swipe>
      <van-icon @click="back" class="f_ioc" name="arrow-left" />
    </div>
    <div class="xqbeij">
      <img src="../../assets/img/miaoshatiao.png" alt />
      <div class="jiage" v-if="list.sellPrice > 0">
        <div class="jiage_A" :class="list.sellPrice <= list.activityPrice ? 'linghe' : ''">
          <span class="jin">￥</span>
          <span>{{ list.activityPrice ? list.activityPrice.toFixed(2) : '' }}</span>
        </div>
        <del class="jiage_b" v-if="list.sellPrice > list.activityPrice">
          <span class="jin">￥</span>
          <span>{{ list.sellPrice ? list.sellPrice.toFixed(2) : '' }}</span>
        </del>
      </div>
      <div class="jiage" v-else>
        <span class="jiage_A linghe">面议</span>
      </div>
      <div class="shulian" v-if="cname != 'jp'">仅剩 {{ list.stockCount }} 件</div>
      <div class="shijian" v-if="cname != 'jp'">
        <div style="font-size: 12px;">距结束还剩</div>
        <div class="shi_b">
          <!-- <span class="jian">24</span>:
            <span class="jian">54</span>:
          <span class="jian">7</span>-->
          <van-count-down :time="time">
            <template #default="timeData">
              <span class="jian" v-if="time > 86400000">{{ timeData.days }}</span>
              <span class="colon" v-if="time > 86400000">:</span>
              <span class="jian">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="jian">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="jian">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>
    <div class="xq_h1">
      <span class="xq_h1_a">{{ list.partName }}</span>
      <span class="qianggou">限时抢购</span>
    </div>
    <div class="xq_h2">
      <div class="yixuan">已选</div>
      <div class="xuan_a">
        <span class="xin_c">车型</span>
        <span class="xin_b" v-if="chexin">{{ chexin.carBrand }}-{{ chexin.carModel }}</span>
      </div>
      <div class="xuan_a">
        <span class="xin_c left">数量</span>
        <span class="xin_b left">
          <van-stepper v-model="value" :disable-plus="list.countLimit == 1" disable-input />
        </span>
      </div>
      <div class="xuan_a">
        <span class="xin_c">物流</span>
        <span class="xin_b">
          <!-- （8月30日） -->
          <span class="ciri">次日达</span>现货，预计明天可送达
        </span>
      </div>
      <div class="xuan_a" style="margin-top: 16px;">
        <span class="xin_c left" style="line-height: 13px;">门店</span>
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{ shangjia.name }}</div>
          <div class="dian_h2">
            {{ juli }}km
            <span>免运费</span>
          </div>
          <div class="dian_h3">{{ shangjia.city }}{{ shangjia.district }}{{ shangjia.address }}</div>
        </div>
      </div>
    </div>
    <div class="canpin">产品说明</div>
    <van-row class="msgHtmlBox ql-snow ql-editor" v-html="list.content"></van-row>
    <div style="height:60px;"></div>
    <van-goods-action>
      <van-goods-action-icon icon="phone-o" text="电话咨询" @click="onClickIcon">
        <a :href="'tel:' + shangjia.tel1">联系门店</a>
      </van-goods-action-icon>
      <van-goods-action-icon icon="service-o" text="在线客服" @click="onClickIcon" />
      <van-goods-action-icon icon="star-o" text="收藏" @click="onClickIcon" />
      <van-goods-action-button v-if="time < 0 && cname != 'jp'" type="danger" color="#ADADAD" text="活动已结束" :disabled="time < 0" />
      <van-goods-action-button v-else-if="list.stockCount <= 0 && cname != 'jp'" type="danger" color="#ADADAD" text="商品已售罄" :disabled="list.stockCount <= 0" />
      <van-goods-action-button v-else-if="list.sellPrice <= 0" type="danger" color="#ADADAD" text="商品需要面议" :disabled="list.sellPrice <= 0" />
      <van-goods-action-button v-else type="danger" text="立即购买" @click="onClickButton" />
    </van-goods-action>
  </div>
</template>

<script>
import userModel from "../../api/user";
import {
  onlineDetails,
  defaultcarInfo,
  getGarageInfo,
  config,
} from "../../api/check.js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: {},
      active: "0",
      time: 0,
      // timeData: {
      //   hours: 10,
      //   minutes: 20,
      //   seconds: 20
      // },
      value: 1,
      switch: 1,
      juli: 0,
      cid: null,
      Aswitch: "",
      id: "",
      cname: "",
    };
  },
  methods: {
    onClickIcon() {},
    qiehuan(row) {
      this.active = row;
    },
    onClickButton() {
      var item = this.list;
      var goods = [
        {
          attrImg: item.coverImg1,
          disc: 10,
          discStatus: 0,
          goodsId: item.id,
          goodsNum: this.value,
          goodsType: 1,
          price: item.activityPrice ? item.activityPrice : item.sellPrice,
          sellPrice: item.sellPrice,
          title: item.partName,
          type: this.cname == "jp" ? 14 : 0,
        },
      ];
      var orderTotal = item.activityPrice
        ? item.activityPrice * this.value
        : item.sellPrice * this.value;

      var orderTotalNovip = item.sellPrice
        ? item.sellPrice * this.value
        : item.sellPrice * this.value;

      var services = [];
      var xiadan = {
        services: services,
        goods: goods,
        shareId: this.cid || null,
        type: this.cname == "jp" ? 14 : 0,
        orderTotal: orderTotal,
        orderTotalNovip: orderTotalNovip,
        num: this.value,
        goodsId: this.list.id,
      };
      sessionStorage.removeItem("xiadan");
      sessionStorage.setItem("xiadan", JSON.stringify(xiadan));
      this.$router.push({
        path: "place_order",
        query: {
          // Aswitch: 2,
          Aswitch: this.cname == "jp" ? 1 : 2,
          cname: this.cname,
        },
      });
    },
    onLoad() {
      var _this = this;
      const data = {
        id: this.id,
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      userModel
        .miaoshadetails(data)
        .then((e) => {
          loading.clear();
          this.list = e.data;
          _this.$nextTick(() => {
            _this.getConfig();
          });
          let date = new Date().getTime();
          var time1 = e.data.endTime.replace(/-/g, "/");
          var date2 = new Date(time1).getTime();
          var staytimeGap = date2 - date;
          this.time = staytimeGap;
          // let stayHour = Math.floor(staytimeGap / (3600 * 1000));
          // let leave1 = staytimeGap % (3600 * 1000);
          // let stayMin = Math.floor(leave1 / (60 * 1000));
          // let leave2 = leave1 % (60 * 1000);
          // let staySec = Math.floor(leave2 / 1000);
          // console.log(stayHour + "时" + stayMin + "分" + staySec + "秒");
          // this.stayHour = stayHour;
          // this.stayMin = stayMin;
          // this.time =

          var isWxMini = window.__wxjs_environment === "miniprogram";
          if (isWxMini) {
            var link =
              "https://shopping.car-posthouse.cn/list_miao_details?gid=" +
              that.shangjia.id +
              "&cid=" +
              that.userInfo.id +
              "&id=" +
              that.id +
              "&cname=" +
              that.cname +
              "&Aswitch=" +
              that.Aswitch +
              "&denglu=" +
              true;
            wx.miniProgram.postMessage({
              data: {
                url: link,
              },
            });
          }
        })
        .catch(() => loading.clear());
    },
    //获取车辆信息
    getmodel() {
      //
      defaultcarInfo().then((e) => {
        console.log(e.data);
        if (e.data == undefined) {
          sessionStorage.setItem("chexin", null);
        } else {
          this.chexin = e.data;
          sessionStorage.setItem("chexin", JSON.stringify(e.data));
        }
        // if(e.data)
      });
    },
    getConfig() {
      let that = this;
      config({
        url: location.href.split("#")[0],
        gid: sessionStorage.getItem("Agid")
          ? sessionStorage.getItem("Agid")
          : 208,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData"],
          });
          var link =
            "https://shopping.car-posthouse.cn/list_miao_details?gid=" +
            that.shangjia.id +
            "&cid=" +
            that.userInfo.id +
            "&id=" +
            that.id +
            "&cname=" +
            that.cname +
            "&Aswitch=" +
            that.Aswitch +
            "&denglu=" +
            true;
          // console.log(link);
          var desc =
            that.userInfo.realName + "已参与活动，邀你一起参与共享优惠";
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: that.list.productExplain,
              desc: "东莞黄江驿车驹·宏开店",
              link: link,
              imgUrl: that.list.coverImg1
                ? that.list.coverImg1
                : "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/163877524258233.png?Expires=1954135242&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=PwQzEWBL5mt69kChKWmzeVhAFf8%3D",
              success: function () {},
            });
          });
        } else {
          that.$toast(res.data.msg);
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "list"
      // });
    },
  },
  created() {
    this.Aswitch = this.$route.query.Aswitch;
    this.id = this.$route.query.id;
    this.cname = this.$route.query.cname;
    this.gid = this.$route.query.gid;
    this.cid = this.$route.query.cid;
    console.log(this.cname);
    if (localStorage.getItem("Authorization") == null) {
      // if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&cid=" +
        this.$route.query.cid +
        "&cname=" +
        this.$route.query.cname +
        "&Aswitch=" +
        this.$route.query.Aswitch +
        "&id=" +
        this.$route.query.id;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      sessionStorage.setItem("id", this.$route.query.id);
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    } else {
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        })
        .catch(() => loading.clear());
      this.getmodel();
    }
    this.onLoad();
    // console.log(this.$route.query);
    if (JSON.parse(localStorage.getItem("JD")) != null) {
      var radLat1 = (this.wd * Math.PI) / 180.0;
      var radLat2 = (this.shangjia.positionWd * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b =
        (this.jd * Math.PI) / 180.0 -
        (this.shangjia.positionJd * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      this.juli = s.toFixed(2);
    }
  },
  mounted() {
    this.active = this.$route.query.status;
    // this.backziding();
    console.log(this.active);
  },
};
</script>

<style lang="less" scoped>
.ord_lis .order_list:nth-of-type(n + 2) {
  margin-top: 10px;
}

.my-swipe {
  position: relative;
  //   height: 362px;
}

.van-swipe-item {
  display: flex;
}

.xq_img {
  width: 100%;
  margin: auto;
  // width: 260px;
  height: 251px;
}

.xqbeij {
  position: relative;

  width: 100%;
  //   height: 40px;
}

.f_ioc {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin-top: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(31, 45, 61, 0.3);
  border-radius: 50%;
}

.jiage {
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 60px;
  margin-top: 10px;
  font-size-adjust: 0.58;
  font-family: Times;
}

.jin {
  font-size: 12px;
  color: #fff;
}

.jiage_A {
  font-size: 23px;
  font-weight: bold;
  line-height: 14px;
}

.linghe {
  line-height: 30px;
}

.shijian {
  font-size: 12px;
  color: #ef166d;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  // margin-top: px;

  text-align: center;
}

.shulian {
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 25px;
  margin-right: 135px;
  text-align: center;
}

.xq_h1 {
  background: #fff;
  overflow: hidden;
  padding: 12px 15px 20px 15px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  border-bottom: 8px solid #f5f5f5;
}

.canpin {
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}

.xq_h2 {
  padding: 10px 15px 15px 15px;
  border-bottom: 8px solid #f5f5f5;
}

.xin_c {
  color: #999;
}

.ciri {
  font-size: 10px;
  background: #c9e5f6;
  border-radius: 2px;
  border: 0.5px solid #87bcda;
  color: #309eda;
  padding: 1px 3px;
  margin-right: 5px;
  line-height: 10px;
  display: inline-block;
}

.mendiantu {
  width: 80px;
  height: 55px;
}

.dian_h1 {
  font-size: 15px;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}

.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-goods-action-button--last {
  border-radius: 20px;
}

.dian_h2 {
  color: #666;
  line-height: 14px;
  margin-top: 8px;
}

.dian_ha {
  margin-left: 10px;
  width: 210px;
}

.xin_b {
  margin-left: 12px;
  font-size: 13px;
}

.xuan_a {
  font-size: 13px;
  overflow: hidden;
  //   line-height: 35px;
  margin: 5px 0;
}

.yixuan {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.xq_h1_a {
  line-height: 12px;
  font-size: 15px;
  font-weight: bold;
}

.qianggou {
  display: inline-block;
  background: #f1dadc;
  color: #f23756;
  line-height: 14px;
  padding: 2px 3px;
  margin-left: 8px;
  border-radius: 2px;
  font-size: 13px;
}

.jian {
  display: inline-block;
  background: #ef166d;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  // padding: 0px 3px;
  width: 18px;
  text-align: center;
}

.colon {
  display: inline-block;
  // margin: 0 4px;
  color: #ef166d;
}

.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;

  background-color: #ef166d;
}

.shi_b {
  line-height: 14px;
}

.jiage_b {
  line-height: 12px;
}

.xqbeij img {
  width: 100%;
}

.ql-editor {
  margin: 2px 15px;
  // margin-top: 15px;
  font-size: 14px;
  overflow: hidden;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}

.ql-editor p img {
  width: 100%;
  outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox >>> img {
  width: 100%;
}

/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>